import axios from "axios";
import Swal from "sweetalert2";

export default {
  methods: {
    confirmDelete(modul, id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(modul, id);
        }
      });
    },
    deleteData(modul, id) {
      try {
        axios
          .get(`${this.$url}/v2/${modul}/delete/` + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Gagal",
              text: error.response.data.message,
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.fetchData();
            });
            console.log(error.response.data.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
